import React from "react";
import Card from "../components/Card";
import { useTranslation } from "react-i18next";

function Contact() {
    const { t } = useTranslation();

    return (
        <>
            <Card id="contacto" title={t("contact.title")}>
                <p>
                    <strong>{t("contact.email")}</strong>
                    <i className="fas fa-envelope" style={{ marginLeft: "10px", marginRight: "8px" }}></i>
                    gonzalo.lobos.ramirez@gmail.com
                </p>
                <p>
                    <strong>{t("contact.phone")}</strong>
                    <i className="fas fa-phone" style={{ marginLeft: "10px", marginRight: "8px" }}></i>
                    +569 659 610 86
                </p>

            </Card>
        </>
    );
}

export default Contact;
